import React,{useRef,useEffect,useState}  from "react";
import Layout from "../components/layout";
import styled from "styled-components";
import mainjson from '../config/main';
import axios from "axios";
import {TokenValidator,LoggedIn} from "../utils/LoginFunctions";
import {Button,Error} from "../styles/Global"
import Helmet from "react-helmet"

const Container = styled.div`
  background-color:#212529;
  
`;

const CenteredContainer = styled.div`
    font-family: Poppins,sans-serif;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    font-size: 14px;
    font-weight: 300;
    box-sizing: border-box;
    outline: 0;
    width: 400px;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid transparent;
    border-radius: 0;
    margin-bottom: 20px;
    top:10%;
    padding: 1.25rem;
`;

const Table = styled.table`
    font-family: Poppins,sans-serif;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    font-size: 14px;
    font-weight: 300;
    box-sizing: border-box;
    outline: 0;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
`;


const Input = styled.input.attrs({type:"text"})`
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    font-family: inherit;
    overflow: visible;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    background-clip: padding-box;
    color: #212529;
    min-height: 38px;
    display: initial;
    background-color: rgba(0,0,0,0);
    background-position: center bottom,center calc(100% - 1px);
    background-repeat: no-repeat;
    background-size: 0 2px,100% 1px;
    padding: 0;
    transition: background 0s ease-out 0s;
    background-image: linear-gradient(#fb9678,#fb9678),linear-gradient(#e9ecef,#e9ecef);
    border: 0 none;
    border-radius: 0;
    box-shadow: none;
    float: none;
`

const Password = styled(Input).attrs({ 
    type: 'password',
  })`
   
  `
const Heading = styled.h3`
  box-sizing: border-box;
  outline: 0;
  margin-top: 0;
  font-family: Poppins,sans-serif;
  font-weight: 300;
  line-height: 1.2;
  color: inherit;
  font-size: 1.5rem;
  text-align: center!important;
  margin-bottom: 20px;
`

const Logo = styled.h3`
background-image: url("/img/logo.jpg");
width:150px;
height:150px;
background-size:contain;
margin-left: auto;
margin-right: auto;
margin-bottom:30px;
`



export default function Page() {
    const loginField = useRef();
    const passwordField = useRef();
    let token = null;
    let level = null;
    const [error, setError] = useState("");

    function submit(){

        let body = {
            email:loginField.current.value,
            password:passwordField.current.value,
            agent:navigator.userAgent
        }

        axios({
            method: "post",
            url: mainjson().login_path,
            data: body,
          })
        .then((response) => {
            console.log(response);
            console.log(response.data.token);
           if (response.data.token) {
               console.log(response.data);
               token = response.data.token;
               level = response.data.level;
               localStorage.setItem('token', token);
               localStorage.setItem('level', level);
               //console.log("login",token,level)
               window.location = mainjson().start_page;
           }
           else {
            setError("E-mail and or password not correct");
           }
        })
        .catch((error) => {
            console.log("error "+error);
            setError("Unknown error");
        });
       
    }

    function removeError(){
        setError("");
    }

    async function CheckToken(){
        let result = await TokenValidator(false);
        console.log("result ",result);
        if (result) {
            console.log('redirect');
            window.location = mainjson().start_page;
        }
    }

    useEffect(() => {
        let token = localStorage.getItem('token');
        if (token) {
            console.log("validate it "+token);
            CheckToken();
        }
    }, []);



  return (
    
    <Layout>
    <CenteredContainer>
        <Logo></Logo>
        <Heading>Sign in</Heading>
        <Table>
            <tbody>
            <tr><td><Input placeholder="E-mail" onFocus={removeError} ref={loginField} /></td></tr>
            <tr><td><Password placeholder="Password" onFocus={removeError} ref={passwordField} /></td></tr>
            <tr><td><Error>{error}</Error>&nbsp;</td></tr>
           <tr><td><Button onClick={submit} value='submit'>Log In</Button></td></tr>
           </tbody>
        </Table>
    </CenteredContainer>
    </Layout>
  );
}
